<i18n>
{
  "en": {
    "heading": "My Playlists",
    "beta": "BETA",
    "public": "Public",
    "private": "Private",
    "movie_count": "no movies | 1 movie | movies",
    "btn_create_new_playlist": "Create New Playlist",
    "btn_create_new_playlist_disabled": "Playlist limit reached",
    "mdl_title_create": "New Playlist",
    "mdl_text_create": "Give your playlist a name",
    "mdl_title_confirm": "Confirm",
    "mdl_text_confirm": "Are you sure you want to delete this playlist?",
    "mdl_btn_create": "Create",
    "mdl_btn_cancel": "Cancel",
    "mdl_btn_confirm_delete": "Yes, delete it!",
    "ntf_create_playlist_0": "New playlist '",
    "ntf_create_playlist_1": "' has been created successfully",
    "ntf_delete_playlist_0": "Deleted playlist '",
    "ntf_delete_playlist_1": "' successfully",
    "alert_name_exists": "This playlist name already exists",
    "alert_name_invalid": "Invalid playlist name"
  },
  "ja": {
    "heading": "再生リスト",
    "beta": "β版",
    "public": "公開",
    "private": "非公開",
    "movie_count": "0 本の動画 | 1 本の動画 | 本の動画",
    "btn_create_new_playlist": "新しい再生リストを作成",
    "btn_create_new_playlist_disabled": "再生リスト数の上限に達しました",
    "mdl_title_create": "新しい再生リスト",
    "mdl_text_create": "再生リストの名前を入力",
    "mdl_title_confirm": "確認",
    "mdl_text_confirm": "この再生リストを削除してもよろしいですか？",
    "mdl_btn_create": "作成",
    "mdl_btn_cancel": "キャンセル",
    "mdl_btn_confirm_delete": "削除",
    "ntf_create_playlist_0": "再生リスト「",
    "ntf_create_playlist_1": "」が作成されました",
    "ntf_delete_playlist_0": "再生リスト「",
    "ntf_delete_playlist_1": "」を削除しました",
    "alert_name_exists": "この名前はすでに使用されています",
    "alert_name_invalid": "無効な名前です"
  }
}
</i18n>

<template>
<div class="contents playlist">
  <div class="heading">
    <h1><span>{{ $t('heading') }}</span><span class="beta-tag">{{ $t('beta') }}</span></h1>
    <button class="button-fill button-default button-small"
      v-if="customListsEnabled && numCustomLists < customListLimit"
      @click="showCreateModal">{{ $t('btn_create_new_playlist') }}</button>
    <button class="button-fill button-default button-small"
      v-else-if="customListsEnabled && numCustomLists >= customListLimit"
      disabled>{{ $t('btn_create_new_playlist_disabled') }}</button>
  </div>

  <div class="list list--default">
    <div class="flex-grid">
      <div v-for="(playlist, index) in sortedFilteredPlaylists" :key="playlist.playlist_id" class="grid-item">
        <router-link :to="`/playlist/?list=${playlist.playlist_id}`" class="entry">
          <div class="entry-media">
            <div class="media-thum">
              <img
                v-if="playlist.firstMovieThumbSrc"
                :src="(posterFallbackTries[playlist.playlist_id]) ? posterFallbackURLs[playlist.playlist_id] : playlist.firstMovieThumbSrc"
                @error="useFallbackImage(index, playlist.playlist_id)"
                class="media-thum-image"
                loading="lazy"
                v-sfw />
              <img v-else src="/img/common/now_printing_landscape.png" class="media-thum-image" loading="lazy" />
            </div>
          </div>
          <div class="entry-meta">
            <div v-if="playlist.type === 'default'" class="meta-title">{{ translationMap[playlist.name][locale] }}</div>
            <div v-else class="meta-title">{{ playlist.name }}</div>
            <div class="meta-data">
              <span v-if="publicListsEnabled">{{ playlist.isPublic ? $t('public') : $t('private') }}&nbsp;&bull;&nbsp;</span>
              <span v-if="playlist.totalMovies > 1">{{ playlist.totalMovies }} {{ $tc('movie_count', 2) }}</span>
              <span v-else-if="playlist.totalMovies == 1">{{ $tc('movie_count', 1) }}</span>
              <span v-else>{{ $tc('movie_count', 0) }}</span>
              <!-- can only delete custom playlists -->
              <span v-if="playlist.type === 'custom'">&nbsp;&bull;&nbsp;<span class="btn-remove" @click="showConfirmModal(playlist.playlist_id, playlist.name, $event)"><i class="icon-trash-o"></i></span></span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div><!-- /.list -->

  <!-- create new playlist modal -->
  <modal name="ui-modal-create" class="modal-overwrite" draggable="false">
    <div class="create-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeCreateModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_create') }}</div>
      <div class="modal-section">
        <span class="section-top">{{ $t('mdl_text_create') }}</span>
        <div class="section-input">
          <input type="text" placeholder="" v-model="newPlaylistName" :maxlength="maxChars" @focus="showAlert = false">
          <div class="limit">{{ maxChars - newPlaylistName.length }}/{{ maxChars }}</div>
          <div class="alert" v-if="showAlert">{{ $t(alertText) }}</div>
          <button class="button-fill button-default button-small" :disabled="!newPlaylistName.length" @click="createPlaylist(newPlaylistName)">{{ $t('mdl_btn_create') }}</button>
        </div>
      </div>
    </div>
  </modal>

  <!-- confirm cancel modal -->
  <modal name="ui-modal-confirm" class="modal-overwrite" draggable="false">
    <div class="confirm-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeConfirmModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_confirm') }}</div>
      <div class="modal-section">
        <span class="section-top">{{ $t('mdl_text_confirm') }}</span>

        <button class="button-close button-fill button-default button-small" @click="closeConfirmModal">{{ $t('mdl_btn_cancel') }}</button>
        <button class="button-fill button-default button-small" @click="deletePlaylist(selectedPlaylistId, selectedPlaylistName)">{{ $t('mdl_btn_confirm_delete') }}</button>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-loop-func: 0 */

import orderBy from 'lodash/orderBy';
import validator from 'validator/validator';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import EventBus from '@/assets/js/utils/EventBus';

const bfAPI = new BifrostAPI();

export default {
  data() {
    return {
      playlists: [],
      selectedPlaylistId: '',
      selectedPlaylistName: '',
      newPlaylistName: '',
      showAlert: false, // flag for alert message
      alertText: '',
      posterFallbackURLs: {},
      posterFallbackTries: {},
    };
  },
  created() {
    // non-reactive consts
    this.maxChars = 100;

    this.getPlaylists();
  },
  methods: {
    getPlaylists() {
      bfAPI.getPlaylists().then((response) => {
        if (response.status === 200) {
          this.playlists = response.data.data;

          console.log('%c this.playlists', 'color:#fff', this.playlists);

          for (let i = 0; this.playlists.length > i; i += 1) {
            // get each playlist's movie count
            this.$set(this.playlists[i], 'totalMovies', Object.keys(this.playlists[i].movies).length);

            // get the first movie of each playlist for thumbnail src
            if (Object.keys(this.playlists[i].movies).length) {
              bfAPI.getPlaylistMovies(this.playlists[i].playlist_id, 0, 1).then((result) => {
                if (response.status === 200) {
                  const firstMovie = result.data.data.movieData[0];
                  if (firstMovie) {
                    this.$set(
                      this.playlists[i],
                      'firstMovieThumbSrc',
                      (this.locale === 'en') ? `/assets/sample/${firstMovie.MovieDateID}/poster_en.jpg` : `/assets/sample/${firstMovie.MovieDateID}/str_s.jpg`,
                    );
                    this.$set(this.posterFallbackURLs, this.playlists[i].playlist_id, `/assets/sample/${firstMovie.MovieDateID}/str.jpg`);
                  }
                }
              });
            }
          }
        }
      });
    },
    createPlaylist(playlistName) {
      // sanitize user input
      let plName = this.sanitizeInput(playlistName);

      if (plName === '') {
        // if sanitizeInput() returns empty, meaning input contains only illegal script tags
        // which have been replaced completey; clear input box and display alert msg
        this.newPlaylistName = '';
        this.showAlert = true;
        this.alertText = 'alert_name_invalid';
      } else {
        // replace multiple whitespaces with 1 whitespace to prevent extra spaces between words
        plName = plName.replace(/\s+/g, ' ');
        // check if user input playlist name is same as our default playlist names (case insensitive)
        Object.keys(this.translationMap).forEach((pl) => {
          if (plName.toLowerCase() === this.translationMap[pl][this.locale].toLowerCase()) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          }
        });
      }

      if (!this.showAlert) {
        bfAPI.createPlaylist('custom', plName, false).then((response) => {
          // if a new playlist is created you'll see the data property
          // otherwise if the playlist already exists you'll just get the playlistCount property
          if (response.status === 201 && response.data.data) {
            this.closeCreateModal();
            // show notification
            EventBus.$emit('notification:event:generic', {
              text: this.$t('ntf_create_playlist_0') + plName + this.$t('ntf_create_playlist_1'),
              duration: 3000,
            });

            // analytics
            this.$analytics.trackEvent('Playlist', 'Create Playlist', plName);
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'Add Playlist',
              playlist_type: 'Custom',
              playlist_name: plName,
            });

            // update playlists
            this.getPlaylists();
          } else if (response.status === 201 && !response.data.data) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          } else if (response.status === 400 && response.data.error === 'playlist_name invalid') {
            this.newPlaylistName = '';
            this.showAlert = true;
            this.alertText = 'alert_name_invalid';
          }
        });
      }
    },
    sanitizeInput(input) {
      // trim(): trim characters (whitespace by default) from both sides of the input
      // escape(): replace <, >, &, ', " and / with HTML entities
      let cleanInput = input.replace(/(<([^>]+)>)/ig, '');
      cleanInput = validator.trim(cleanInput);
      cleanInput = validator.escape(cleanInput);

      return cleanInput;
    },
    deletePlaylist(playlistId, playlistName) {
      bfAPI.deletePlaylist(playlistId).then((response) => {
        if (response.status === 200) {
          this.closeConfirmModal();
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_delete_playlist_0') + playlistName + this.$t('ntf_delete_playlist_1'),
            duration: 3000,
          });

          // analytics
          this.$analytics.trackEvent('Playlist', 'Delete Playlist', playlistName);
          Analytics.sendCustomEvent('playlist', {
            playlist_action: 'Delete Playlist',
            playlist_type: 'Custom',
            playlist_name: playlistName,
          });

          // update playlists
          this.getPlaylists();
        }
      });
    },
    showCreateModal() {
      this.showAlert = false;
      this.newPlaylistName = '';
      this.$modal.show('ui-modal-create');
    },
    closeCreateModal() {
      this.showAlert = false;
      this.newPlaylistName = '';
      this.$modal.hide('ui-modal-create');
    },
    showConfirmModal(playlistId, playlistName, event) {
      // prevent redirection to playlist detail page
      event.preventDefault();

      this.selectedPlaylistId = playlistId;
      this.selectedPlaylistName = playlistName;
      this.$modal.show('ui-modal-confirm');
    },
    closeConfirmModal() {
      this.selectedPlaylistId = '';
      this.selectedPlaylistName = '';
      this.$modal.hide('ui-modal-confirm');
    },
    useFallbackImage(index, playlistID) {
      // missing movie poster thumbnail, fallback to large poster
      this.$set(this.posterFallbackTries, playlistID, true);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    numCustomLists() {
      return this.playlists.filter(playlist => playlist.type === 'custom').length;
    },
    customListLimit() {
      return this.$store.getters['playlist/custom_limit'];
    },
    customListsEnabled() {
      return this.$store.getters['playlist/isCustomListsEnabled'];
    },
    publicListsEnabled() {
      return this.$store.getters['playlist/isPublicListsEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    sortedFilteredPlaylists() {
      // sort playlists by default types first (desc order), and then playlist name by alpha (asc
      // order), and then filter if applicable
      const orderedList = orderBy(this.playlists, ['type', 'name'], ['desc', 'asc']);
      const filteredList = (!this.customListsEnabled) ? orderedList.filter(item => item.type === 'default') : orderedList;

      return filteredList;
    },
  },
};
</script>

<style lang="scss" scoped>
/* temp beta tag style */
.heading h1 {
  span {
    display: inline-block;
    vertical-align: middle;

    &.beta-tag {
      margin-left: 0.5em;
      font-size: 0.6em;
      font-weight: normal;
      padding: 1px 5px;
      color: #fff;
      background-color: #999;
      border-radius: 3px;
    }
  }
}
</style>
