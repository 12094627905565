<i18n>
{
  "en": {
    "browser_title": "My Playlists"
  },
  "ja": {
    "browser_title": "再生リスト"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <span v-if="playlistEnabled"><playlist-list /></span>
</main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import PlaylistList from '@/components/playlist/playlistList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'playlist-list': PlaylistList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_playlist.scss';
</style>
